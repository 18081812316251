<template>
    <div>
      <v-card >
        <v-toolbar
            color="#4ECDC4"
            dark
            flat
        ><span class="text-h6">無料コンテンツの追加</span>
        </v-toolbar>
      
        <v-card-text>
          {{item.fileName}}を追加しますか？

          
        </v-card-text>
         
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="ma-2"
              outlined
              color="#78909C"   
              @click="close">キャンセル
          </v-btn>
          <v-btn 
            color="#1DE9B6"   
            class="white--text"
            @click="addContent()" 
            >追加
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
     
      </div>
      

</template> 

<script>

export default {
  name: 'DialogAddContent',
  props:["item"],
  components: {

  },
  data () {
    return {
   
   
    }
  },
  

  watch : {
    renameItemName: function(){
      this.isOverlap = this.desserts.some((u) => u[this.dicName] == this.renameItemName )
      this.enableRename = !this.isOverlap && (this.name != this.renameItemName) && this.renameItemName; 
    }
  },

  methods: {
    addContent: function(){
      this.$emit("addContentEmit",this.item)
    },

    close: function(){
       this.$emit("closeEmit")
    }

  },
}

</script>
